import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { LoggingService } from "./log.service";
import { Observable, throwError } from "rxjs";
import { SettingsService } from "../settings/settings.service";
import { CalendarEvents } from "../interfaces/admin.interface";

@Injectable()
export class AppService {
  public paging = true;
  public itemsPerPage = environment.rows; // server pagination
  public page: number = 1;
  public numPages = 1;
  public totalItems = 1;

  constructor(
    private http: HttpClient,
    private logger: LoggingService,
    private router: Router,
    private settings: SettingsService
  ) {}

  // Rest Items List Service
  getList(path, filters) {
    // stringa per chiamata da fare
    let queryStr = "";
    let i = 0;
    let stringAmp = "";
    // imposto i parametri del filtro
    if (filters != null) {
      Object.keys(filters).forEach(function (key) {
        if (filters[key] != null && filters[key].toString() !== "") {
          if (i > 0) {
            stringAmp = "&";
          }
          queryStr = queryStr + stringAmp + key + "=" + filters[key];
          i++;
        }
      });
    }

    // creo tutta la chiamata completa di paginazione e righe
    queryStr =
      environment.restBaseUrl +
      path +
      "?" +
      queryStr +
      "&paging=" +
      this.paging +
      "&page=" +
      this.page +
      "&rows=" +
      this.itemsPerPage;

    // clean url
    queryStr = queryStr.replace("?&", "?");

    this.logger.log("Chiamata URL lista:" + queryStr, "", 200);

    return this.http.get<any[]>(queryStr, this.getRequestOptionArgs()).pipe(
      map((response: HttpResponse<any>) => {
        const outcome = response["outcome"];
        const data = response["data"];
        this.totalItems = data["total"];

        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return data;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        const res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      })
    );
  }

  // Rest Items Service: Read one element (detail)
  getElement(path) {
    path = environment.restBaseUrl + path;
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(
      map((response: HttpResponse<any>) => {
        const outcome = response["outcome"];
        const dataResponse = response["data"];
        this.totalItems = dataResponse["total"];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      })
    );
  }

  // Rest Items Service: Read all REST Items
  getAll(path) {
    path = environment.restBaseUrl + path;
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(
      map((response: HttpResponse<any>) => {
        const outcome = response["outcome"];
        const dataResponse = response["data"];
        this.totalItems = dataResponse["total"];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      })
    );
  }

  getAllMap(path: string): Observable<CalendarEvents> {
    path = environment.restBaseUrl + path;
    return this.http
      .get<Map<string, any[]>>(path, this.getRequestOptionArgs())
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  // get element list by data passed
  getAllFromData(path, requestData): any {
    path = environment.restBaseUrl + path;
    return this.http
      .post<any[]>(path, requestData, this.getRequestOptionArgs())
      .pipe(
        map((response: HttpResponse<any>) => {
          const outcome = response["outcome"];
          const dataResponse = response["data"];
          if (outcome.success === true) {
            this.logger.log("Service:", "SUCCESS", 200);
            return dataResponse;
          } else {
            this.logger.log("Service:", "FAILURE", 200);
            outcome.message = this.settings.manageErrorMsg(outcome);
            return outcome;
          }
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          this.logger.log("Error Response:", errorResponse, 200);
          let res: any = errorResponse.error;
          if (res.outcome.code === "0005" || res.outcome.code === "0007") {
            this.settings.sessionExpired();
          }
          return throwError(errorResponse.error);
        })
      );
  }

  // creation of new element
  newElement(path, elementRequest): Observable<any> {
    path = environment.restBaseUrl + path;
    return this.http
      .post<any[]>(path, elementRequest, this.getRequestOptionArgs())
      .pipe(
        map((response: HttpResponse<any>) => {
          const outcome = response["outcome"];
          const dataResponse = response["data"];
          if (outcome.success === true) {
            this.logger.log("Service:", "SUCCESS", 200);
            return response;
          } else {
            this.logger.log("Service:", "FAILURE", 200);
            outcome.message = this.settings.manageErrorMsg(outcome);
            return outcome;
          }
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          this.logger.log("Error Response:", errorResponse, 200);
          let res: any = errorResponse.error;
          if (res.outcome.code === "0005" || res.outcome.code === "0007") {
            this.settings.sessionExpired();
          }
          return throwError(errorResponse.error);
        })
      );
  }

  // modify of an element
  editElement(path, elementRequest): Observable<any> {
    path = environment.restBaseUrl + path;

    return this.http
      .patch<any[]>(path, elementRequest, this.getRequestOptionArgs())
      .pipe(
        map((response: HttpResponse<any>) => {
          const outcome = response["outcome"];
          const dataResponse = response["data"];
          if (outcome.success === true) {
            this.logger.log("Service:", "SUCCESS", 200);
            return response;
          } else {
            this.logger.log("Service:", "FAILURE", 200);
            outcome.message = this.settings.manageErrorMsg(outcome);
            return outcome;
          }
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          this.logger.log("Error Response:", errorResponse, 200);
          let res: any = errorResponse.error;
          if (res.outcome.code === "0005" || res.outcome.code === "0007") {
            this.settings.sessionExpired();
          }
          return throwError(errorResponse.error);
        })
      );
  }

  postCalendarEvent(
    path: string,
    status: string,
    ore: number,
    note: string,
    date: string
  ): Observable<any> {
    path = environment.restBaseUrl + path;

    const body = {
      calendarDay: date,
      calendarStatusType: status.toUpperCase(),
      ore: ore,
      message: note ?? "",
    };

    return this.http.post<any[]>(path, body, this.getRequestOptionArgs()).pipe(
      map((response: HttpResponse<any>) => {
        const outcome = response["outcome"];
        const dataResponse = response["data"];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return response;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        let res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      })
    );
  }

  patchCalendarEvent(
    path: string,
    status: string,
    ore: number,
    note: string,
    date: string
  ): Observable<any> {
    path = environment.restBaseUrl + path;

    const body = {
      calendarDay: date,
      calendarStatusType: status.toUpperCase(),
      ore: ore,
      message: note ?? "",
    };

    return this.http.patch<any[]>(path, body, this.getRequestOptionArgs()).pipe(
      map((response: HttpResponse<any>) => {
        const outcome = response["outcome"];
        const dataResponse = response["data"];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return response;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        let res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      })
    );
  }

  deleteUser(path: string): Observable<any> {
    path = environment.restBaseUrl + path;
    return this.http.delete<any[]>(path, this.getRequestOptionArgs()).pipe(
      map((response: any) => {
        const outcome = response["outcome"];
        const dataResponse = response["data"];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return response;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        let res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      })
    );
  }

  // modify of an element
  deleteElement(path: string, elementRequest: any): Observable<any> {
    path = environment.restBaseUrl + path;
    return this.http
      .put<any[]>(path, elementRequest, this.getRequestOptionArgs())
      .pipe(
        map((response: any) => {
          const outcome = response["outcome"];
          const dataResponse = response["data"];
          if (outcome.success === true) {
            this.logger.log("Service:", "SUCCESS", 200);
            return response;
          } else {
            this.logger.log("Service:", "FAILURE", 200);
            outcome.message = this.settings.manageErrorMsg(outcome);
            return outcome;
          }
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          this.logger.log("Error Response:", errorResponse, 200);
          let res: any = errorResponse.error;
          if (res.outcome.code === "0005" || res.outcome.code === "0007") {
            this.settings.sessionExpired();
          }
          return throwError(errorResponse.error);
        })
      );
  }

  invite(idUser: number): any {
    const path = environment.restBaseUrl + "/admin/user/invite/" + idUser;
    const elementRequest: any = {};

    // TODO: inserire la chiamata al servizio corretto!
    return this.http
      .put<any[]>(path, elementRequest, this.getRequestOptionArgs())
      .pipe(
        map((response: HttpResponse<any>) => {
          const outcome = response["outcome"];
          const dataResponse = response["data"];
          if (outcome.success === true) {
            this.logger.log("Service:", "SUCCESS", 200);
            return response;
          } else {
            this.logger.log("Service:", "FAILURE", 200);
            outcome.message = this.settings.manageErrorMsg(outcome);
            return outcome;
          }
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          this.logger.log("Error Response:", errorResponse, 200);
          let res: any = errorResponse.error;
          if (res.outcome.code === "0005" || res.outcome.code === "0007") {
            this.settings.sessionExpired();
          }
          return throwError(errorResponse.error);
        })
      );
  }

  getRequestOptionArgs(): any {
    const token =
      localStorage.getItem("token") != null
        ? localStorage.getItem("token")
        : "";

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "X-Auth-Token": token,
      }),
    };
    return httpOptions;
  }
}
